<template>
  <div>
    <ListingLayout
      v-model="selectedRows"
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :filter-names="filtersName"
      search-label="search"
      vertical-scroll
      :multi-select-rows="this.$admin.hasAccessTo('vehicles.action') && options.tenant_id
        ? true
        : false
      "
      @filterOption="onFiltersChange"
    >
      <template v-slot:actions="{ item }">
        <v-icon
          v-if="
            $admin.can('vehicles.edit') || $admin.can('vehicles.assign-vehicle')
          "
          class="mr-2"
          small
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          v-if="$admin.can('vehicles.delete')"
          class="mr-2"
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

      <template v-slot:assigned="{ item }">
        <div
          :class="item?.test_customers?.length > 0 ? 'activeCustomer' : ''"
          @click="
          item?.test_customers?.length > 0 ? showTestCustomers(item) : ''
          "
        >
          <span>
            {{ item?.assigned?.replace(/_/g, " ") }}
          </span>
        </div>
      </template>

      <!-- add Filters -->
      <!-- <template #addFilters>
      </template> -->
      <!-- add Filters -->

      <!-- Action Modals -->
      <template #actionModals>
        <div
          class="d-flex"
          style="gap: 0.5rem"
        >
          <v-menu
            v-if="selectedRows.length > 0"
            v-model="isAction"
            left
            offset-y
            :close-on-content-click="false"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                title="Assigned / Unassigned of Business"
                v-bind="attrs"
                style="min-width: 30px; max-width: 45px"
                v-on="on"
              >
                <!-- small
            style="height: 40px" -->
                <v-icon>mdi-office-building-cog</v-icon>
              </v-btn>
            </template>
            <v-list
              v-if="isAction"
              style="max-width: 300px"
            >
              <v-radio-group v-model="actionFilter.value">
                <v-list-item
                  v-for="(act, i) in actions"
                  :key="i"
                  two-line
                >
                  <v-list-item-content>
                    <v-list-item-title>
                      <v-radio
                        :value="act.key"
                        :label="$t(act.label)"
                        hide-details
                        :ripple="false"
                      />
                      <!-- @change="() => (actionFilter[act.key] = null)" -->
                    </v-list-item-title>
                    <v-list-item-subtitle v-if="actionFilter.value == 'oem_b2b_id' && i == 0">
                      <v-select
                        v-model="actionFilter.oem_b2b_id"
                        dense
                        :items="authUser.isAdmin
                          ? oemB2bList.filter(
                            (r) => r.tenant_id == options.tenant_id
                          )
                          : oemB2bList
                        "
                        item-value="id"
                        item-text="business_name"
                        clearable
                      />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-radio-group>

              <v-divider />
              <v-list-item-action class="d-flex justify-end mr-2">
                <v-btn
                  color="primary"
                  dark
                  small
                  :loading="isActionLoading"
                  @click="onActionApply"
                >
                  {{ $t("apply") }}
                </v-btn>
              </v-list-item-action>
            </v-list>
          </v-menu>
          <create-vehicle
            v-if="$admin.can('vehicles.create')"
            :filter-options="options"
          />
        </div>

        <edit-vehicle
          v-if="
            ($admin.can('vehicles.edit') ||
              $admin.can('vehicles.assign-vehicle')) &&
              editModal
          "
          :is-visible-dialog="editModal"
          :toggle-edit="toggleEditModal"
          :vehicle="selectedRow"
        />
        <confirmation-modal
          v-if="$admin.can('vehicles.delete')"
          :is-visible="deleteModal"
          :toggle="toggleDeleteModal"
          :row="selectedRow"
        />

        <!-- Test Customer -->
        <test-customer
          v-if="showTestCustomerModal"
          :is-visible-dialog="showTestCustomerModal"
          :toggle-modal="toggleTestCustomerModal"
          :data="selectedCustomer"
        />
        <!-- Test Customer -->
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    CreateVehicle: () => import("./dialogs/CreateVehicle.vue"),

    EditVehicle: () => import("./dialogs/EditVehicleTabs.vue"),
    TestCustomer: () => import("./dialogs/TestCustomer.vue"),

    ConfirmationModal: () =>
      import("@/components/Modals/ConfirmationModal.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
      //
      showTestCustomerModal: false,
      selectedCustomer: {},
      //
      selectedRows: [],
      isAction: false,
      isActionLoading: false,
      actionFilter: {},

      //
      actions: [
        {
          value: false,
          key: "oem_b2b_id",
          label: "assigned_to_business",
          action: "assign",
        },
        {
          value: false,
          key: "unassign",
          label: "unassigned_from_business",
          action: "unassign",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "vehicles/getIsLoadingVehicles",
      meta: "vehicles/getVehiclesMeta",
      list: "vehicles/getVehiclesList",

      oemB2bList: "oemBusiness/getOEMB2BList",
      tenantsList: "tenantsManagement/getTenantOptions",
    }),
    filtersName() {
      return ["search", "is-assigned_only", "customer"];
    },
    _headers() {
      const isICE = this.authUser.user_tech
        ? this.authUser.user_tech
        : this.tenantsList.find((r) => r.id == this.options.tenant_id)
          ?.vehicle_tech;

      const head = [
        {
          text: this.$t("id"),
          align: "left",
          sortable: true,
          value: "id",
          width: "8%",
        },
        ...(this.authUser.isAdmin
          ? [
            {
              text: this.$t("oem_name"),
              align: "left",
              sortable: false,
              value: "tenant_name",
              width: "10%",
              showAdmin: true,
            },
          ]
          : []),

        {
          text: this.$t("ev_make"),
          align: "left",
          sortable: false,
          value: "md_make_name",
          width: "10%",
        },
        {
          text: this.$t("ev_model"),
          align: "left",
          sortable: false,
          value: "md_model_name",
          width: "10%",
        },
        {
          text: this.$t("year"),
          align: "left",
          sortable: false,
          value: "year",
          width: "10%",
        },
        {
          text: this.$t("chassis_no"),
          align: "left",
          sortable: false,
          value: "chassis_no",
          width: "10%",
        },
        {
          text: this.$t("registration_no"),
          align: "left",
          sortable: false,
          value: "vehicle_registration_no",
          width: "10%",
        },
        ...(isICE !== "ice"
          ? [
            {
              text: this.$t("motor_no"),
              align: "left",
              sortable: false,
              value: "motor_no",
              width: "10%",
            },
          ]
          : []),
        // {
        //   text: this.$t("motor_no"),
        //   align: "left",
        //   sortable: false,
        //   value: "motor_no",
        //   width: "10%",
        // },
        {
          text: this.$t("imei"),
          align: "left",
          sortable: false,
          value: "iot_device_imei",
          width: "10%",
        },
        {
          text: this.$t("allotted"),
          align: "left",
          sortable: false,
          value: "assigned",
          width: "10%",
        },
        ...(this.authUser.user_type == null ||
          this.authUser.user_nature == "oem" ||
          this.authUser.isAdmin
          ? [
            {
              text: this.$t("business_name"),
              align: "left",
              sortable: false,
              value: "oem_b2b_business_name",
              width: "10%",
              showFleet: false,
            },
          ]
          : []),

        ...(this.$admin.hasAny([
          "vehicles.assign-vehicle",
          "vehicles.edit",
          "vehicles.delete",
        ])
          ? [
            {
              text: this.$t("actions"),
              align: "left",
              sortable: false,
              value: "actions",
            },
          ]
          : []),
      ];
      return head;
    },
  },
  watch: {
    "subFilters.assignment_type": {
      handler: function (v) {
        if (v == "business") {
          this.$store.dispatch("oemBusiness/list", {
            tenant_id: this.options.tenant_id,
          });
        } else {
          this.$store.commit("oemBusiness/SET_OEM_LIST", []);
        }
      },
    },
    "options.tenant_id": {
      handler: function (v) {
        if (v && this.options.product == "oem") {
          this.selectedRows = [];
          this.$store.dispatch("oemBusiness/list", {
            tenant_id: v,
          });
        } else {
          this.$store.commit("oemBusiness/SET_OEM_LIST", []);
        }
      },
    },

    options() {
      this.fetchData();
    },

    isAction: {
      handler: function () {
        this.actions.forEach((element) => {
          element.value = false;
        });
        this.actionFilter = {};
      },
    },
  },
  mounted() {
    this.$store.dispatch("vehicles/setLoadingValue", true);
  },
  beforeDestroy() {
    const params = {
      name: "vehicles",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    async fetchData() {
      await this.$store.dispatch("vehicles/list", this.options).then(() => {
        if (this.options.page > (this.meta?.lastPage || 1)) {
          this.options.page = 1;
        }
      });
    },

    showTestCustomers(row) {
      this.selectedCustomer = row;
      this.showTestCustomerModal = true;
    },
    toggleTestCustomerModal() {
      return (this.showTestCustomerModal = !this.showTestCustomerModal);
    },
    async onActionApply() {
      const params = {
        tenant_id: this.authUser.tenant_id ?? this.options.tenant_id,
        vehicle_ids: this.selectedRows.map((r) => r.id),
      };
      this.isActionLoading = true;

      if (
        this.actionFilter.value == "oem_b2b_id" &&
        this.actionFilter.oem_b2b_id
      ) {
        params.action = "assign";
        params.oem_b2b_id = this.actionFilter.oem_b2b_id;

        await this.$store
          .dispatch("vehicles/bulkAssignToBusiness", params)
          .then(() => {
            this.fetchData();
            this.$store.dispatch(
              "alerts/success",
              this.$t("assigned_successfully")
            );
          })
          .catch((err) => {
            this.fetchData();
            this.$store.dispatch("alerts/error", err.response.data.message);
          })
          .finally(() => {
            this.isAction = false;
            this.isActionLoading = false;
          });
      } else if (this.actionFilter.value == "unassign") {
        params.action = "unassign";

        await this.$store
          .dispatch("vehicles/bulkAssignToBusiness", params)
          .then(() => {
            this.fetchData();
            this.$store.dispatch(
              "alerts/success",
              this.$t("unassigned_successfully")
            );
          })
          .catch((err) => {
            this.fetchData();
            this.$store.dispatch("alerts/error", err.response.data.message);
          })
          .finally(() => {
            this.isAction = false;
            this.isActionLoading = false;
          });
      }
    },

    // Confirmation of Delete Row
    async deleteSelectedRow(row) {
      try {
        await this.$store.dispatch("vehicles/destroy", row);
        await this.$store.dispatch("iotDevices/list", {
          vehicle_assigned: false,
        });
        this.$store.dispatch("alerts/success", this.$t("deleted_successfully"));
        if (this.list.length === 0 && this.meta.lastPage == this.options.page) {
          this.options.page -= 1; // Move to the previous page
        }
        this.toggleDeleteModal("close");
        this.fetchData();
      } catch (error) {
        this.$store.dispatch("alerts/error", error.response?.data?.message);
      }
    },
  },
};
</script>
<style lang="sass" scoped>
:deep .v-badge
  .v-icon
    color: #20a390 !important


:deep .v-radio
  label
    color: black !important
</style>